import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby';
import ribbon from '../images/home/ribbon.svg';

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const IndexPage = () => {

	const data = useStaticQuery(graphql`
		query {
			logoIntro: file(relativePath: { eq: "2mynz-logo-xl.png" }) {
				childImageSharp {
					fluid(maxWidth: 350) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			header: file(relativePath: { eq: "home/home-header.png" }) {
				childImageSharp {
					fluid(maxWidth: 350) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			users: file(relativePath: { eq: "home/users.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1360) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			student: file(relativePath: { eq: "home/mobile-student.png" }) {
				childImageSharp {
					fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			teacher: file(relativePath: { eq: "home/mobile-teacher.png" }) {
				childImageSharp {
					fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			interactive: file(relativePath: { eq: "home/mobile-interactive.png" }) {
				childImageSharp {
					fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
			video: file(relativePath: { eq: "home/mobile-video.png" }) {
				childImageSharp {
					fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			},
		}
	`)

	return (
		<Layout>
			<SEO title="Home" />
			<div className="home-intro mb-3 mb-md-4">
				<div className="row align-items-center text-center text-sm-left">
					<div className="col-md-6">
						<div className="home-intro__brand mb-3">
							<Img fluid={data.logoIntro.childImageSharp.fluid} />
						</div>
						<h1 className='headline mb-3'>Be inspiring or educate in 2 minutes or less</h1>
						<div className='d-none d-sm-block'>
							<h2 className='mb-3'>Students teach Students is the most engaging, efficient and effective method for learning in small bites</h2>
							<Link to='/about' className='btn mb-3'>Learn more</Link>
						</div>
					</div>
					<div className="col-md-6">
						<div className="home-intro__img">
							<Img style={{ maxHeight: "100%" }} imgStyle={{ objectFit: "contain" }} fluid={data.header.childImageSharp.fluid} />
						</div>
						<div className='d-block d-sm-none mt-2'>
							<h2 className='mb-3'>Students teach Students is the most engaging, efficient and effective method for learning in small bites.</h2>
							<Link to='/about' className='btn'>Learn more</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="card mb-3 mb-md-4">
				<div className="card-img-top">
					<Img fluid={data.users.childImageSharp.fluid} />
				</div>
				<div className="card-body text-center">
					<div className="row">
						<div className="col-md-5 mx-auto pb-0">
							<h2>2MYNZ is the best way to create and share inspiring and educational video content.</h2>
							<p className='mb-3'>We are a new approach to building video content that both engages and educates in just 2 minutes. Plus for the first time users can become stakeholders in a fast growing technology company by simply using the service.</p>
							<Link to='/cooptech' className='btn mb-2'>Learn more</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="card mb-3 mb-md-4">
				<div className="card-body">
					<div className="row align-items-center justify-content-between text-center text-sm-left">
						<div className="col-md-5">
							<h2>What is 2MYNZ?</h2>
							<h4>Educate and Learn from other students</h4>
							<p className='mb-2'>Being able to perfect a message in 2 minutes or less is a valuable skill and with 2MYNZ it also helps educate or inspire others. We are a mobile only application because studies show that students still have more access to smartphones than in-school devices.</p>
							<p className='mb-3'><b>Bonus!</b> We have built a teacher tool to easily integrate 2MYNZ into your classroom every day as the quickest and most engaging tool on your digital shelf.</p>
							<Link to='/about' className='btn mb-2 d-none d-sm-inline-block'>Tell me more</Link>
						</div>
						<div className="col-md-7">
							<div className='embed-container'>
								<iframe src="https://player.vimeo.com/video/500090369" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
							</div>
							<Link to='/about' className='btn mt-3 d-inline-block d-sm-none'>Tell me more</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="row text-center mb-3">
				<div className="col-lg-6 mx-auto">
					<h2 className='mb-0'>For Teachers &amp; Professors</h2>
					<p className='mb-2'>We are sure that you have seen the power of video apps like TikTok and Instagram. You can turn that power into a valuable tool in your classroom. 2MYNZ is an app for High School and University students and their educators that will inject life into homework and group projects.</p>
					<p className='bold h4'>2MYNZ can help you in many ways, here are a few:</p>
				</div>
			</div>

			<div className="row home-help">
				<div className="col-lg-6 d-flex mb-2">
					<div className="card">
						<div className="card-body">
							<div className="row align-items-center align-items-lg-start">
								<div className="col-md-6">
									<div className="home-help--img">
										<Img fluid={data.student.childImageSharp.fluid} />
									</div>
								</div>
								<div className="col-md-6">
									<h2 className='h1'>1.</h2>
									<p>Give your students the ability to create video answers for specific assignments that you will be able to grade using our simple 3 score parameters. Not only will they love producing the videos it will teach them how to breakdown any subject and present it in a concise way. This is a key skill for their future careers and all they need is a smartphone to access 2MYNZ.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-6 d-flex mb-2">
					<div className="card">
						<div className="card-body">
							<div className="row align-items-center align-items-lg-start">
								<div className="col-md-6">
									<div className="home-help__img">
										<Img fluid={data.teacher.childImageSharp.fluid} />
									</div>
								</div>
								<div className="col-md-6">
									<h2 className='h1'>2.</h2>
									<p>Once you have graded their work you can upload their videos to be added to the 2MYNZ video curriculum where top graded videos can be viewed by other students. Peer learning is extremely engaging and efficient plus in the 2MYNZ format accessible from anywhere.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-lg-6 d-flex mb-2">
					<div className="card">
						<div className="card-body">
							<div className="row align-items-center align-items-lg-start">
								<div className="col-md-6">
									<div className="home-help__img">
										<Img fluid={data.interactive.childImageSharp.fluid} />
									</div>
								</div>
								<div className="col-md-6">
									<h2 className='h1'>3.</h2>
									<p>Interactive content generation will enhance and engage your classroom and teach your students next generation communication skills.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-lg-6 d-flex mb-2">
					<div className="card">
						<div className="card-body">
							<div className="row align-items-center align-items-lg-start">
								<div className="col-md-6">
									<div className="home-help__img">
										<Img fluid={data.video.childImageSharp.fluid} />
									</div>
								</div>
								<div className="col-md-6">
									<h2 className='h1'>4.</h2>
									<p>Video production is very familiar to your students so you will be very surprised by the level of creativity they bring to their projects.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card bg-primary mb-2 home-bonus">
				<img className="home-bonus__ribbon" src={ribbon} alt='' />
				<div className="home-bonus__header">
					<h2 className='uppercase mb-0'>Bonus</h2>
				</div>
				<div className="home-bonus__content">
					<h3>2MYNZ is just $6.99 per month for unlimited use for you and your students. If you upload more than 20 qualified videos per month to the 2MYNZ Curriculum the service will then be free for you and your classroom.</h3>
				</div>
			</div>



		</Layout>
	)
}

export default IndexPage
